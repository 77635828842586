import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ProductImage from "../../../images/product/outdoor/P10/Product-Summary-P10-Outdoor.jpg";
import OrderButton from "../../../components/orderbutton";
import PropTypes from "prop-types";
import ProductTable from "../../../components/productTable";
import P10Showcase from "../../../components/product/outdoor/p10Showcase";
import Content from "../../../content/produk/outdoor/P10/Product-Page.json"

const p10 = ({ siteTitle }) => {
    const headerStyle = {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        paddingBottom: "20px",
        position: "relative",
        color: "#2d405f",
    };
    return (
        <Layout>
            <SEO
              title={Content.seo.title}
              description={Content.seo.description}
              lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1 style={headerStyle}>
                            P10 Outdoor LED Display Module
                        </h1>
                        <Row>
                            <Col sm={true}>
                                <Image src={ProductImage} fluid />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2>Product Summary</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    P.10 outdoor merupakan layar LED penuh warna
                                    dengan penggunaan di luar ruangan (outdoor)
                                    sehingga yang cocok digunakan pada public
                                    area, periklanan, reklame digital ,sistem
                                    informasi pemerintahan, sistem informasi
                                    transportasi publik (bandara, stasiun MRT,
                                    pelabuhan, jalan raya, tol) Sangat cocok
                                    dipergunakan sebagai media promosi sebuah
                                    usaha atau lembaga dan lainnya. P.10 outdoor
                                    memiliki kualitas sebagai berikut:
                                </p>
                                <ul style={{ textAlign: "left" }}>
                                    <li>
                                        <i>Brightness System</i> yang lebih
                                        tinggi.
                                    </li>
                                    <li>
                                        Tahan terhadap cuaca dan iklim luar
                                        ruangan.
                                    </li>
                                    <li>
                                        Media yang inovatif, interaktif,
                                        informatif untuk berbagai keperluan.
                                    </li>
                                    <li>Struktur yang kuat dan tahan lama.</li>
                                </ul>
                                <p>
                                    <OrderButton messageText={Content.messageText} />
                                </p>
                            </Col>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Unit Module
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"Unit Module"}
                                    tableData={Content.moduleUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        LED Cabinet Unit
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"LED Unit Cabinet"}
                                    tableData={Content.cabinetUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Contoh Pemasangan P10 Outdoor
                                    </h2>
                                </div>
                                <P10Showcase />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};
p10.propTypes = {
    siteTitle: PropTypes.string,
};

p10.defaultProps = {
    siteTitle: ``,
};
export default p10;
